<template>
  <section id="history-changes">
    <breadcrumbs class="breadcrumbs" />

    <b-card
      no-body
      class="p-0"
    >
      <div class="table-actions p-3 d-flex">
        <date-picker
          type="datetimerange"
          format="dd.MM.yyyy"
          class="elm-calendar"
          placeholder="Выберите период"
          :clearable="false"
        />
      </div>
      <b-table
        ref="table_history_changes"
        :fields="fields"
        :items="items"
        :busy="tableIsBusy"
        empty-filtered-text="Нет записей"
        show-empty
        class="table-history-changes"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Загрузка..."
            />
            <div><strong>Загрузка...</strong></div>
          </div>
        </template>

        <template #head(id)>
          <b-form-checkbox v-model="select_all" />
        </template>

        <template #empty="scope">
          <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
  import Breadcrumbs from '@breadcrumbs'
  export default {
    components: {
      Breadcrumbs
    },
    data: () => ({
      items: [],
      tableIsBusy: false,
      select_all: false,
      selected: []
    }),

    computed: {
      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'w-5 center'
          },
          {
            key: 'date',
            label: 'Дата документа',
            class: 'w-20',
            sortable: true
          },
          {
            key: 'manager',
            label: 'Менеджер',
            class: 'w-20',
            sortable: true
          },
          {
            key: 'comment',
            label: 'Комментарий'
          }
        ]
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])
      }
    }
  }
</script>

<style lang="scss" scoped>
  #history-changes {
    .elm-calendar {
      width: 268px;
      max-width: 100%;
    }
  }
</style>
