var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "history-changes"
    }
  }, [_c('breadcrumbs', {
    staticClass: "breadcrumbs"
  }), _c('b-card', {
    staticClass: "p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "table-actions p-3 d-flex"
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "type": "datetimerange",
      "format": "dd.MM.yyyy",
      "placeholder": "Выберите период",
      "clearable": false
    }
  })], 1), _c('b-table', {
    ref: "table_history_changes",
    staticClass: "table-history-changes",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.items,
      "busy": _vm.tableIsBusy,
      "empty-filtered-text": "Нет записей",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }